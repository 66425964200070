import React from "react";
import Img from "gatsby-image";

export interface CardProps {
  fluidImg: any;
  title: string;
  description: string;
}

export const Card = ({
  fluidImg,
  title,
  description
}: CardProps): React.ReactElement => {
  return (
    <>
      <div className="max-w-sm w-full lg:max-w-full lg:flex hover:shadow-lg cursor-pointer mb-3">
        <div
          className="h-auto lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          title={title}
        >
          <Img fluid={fluidImg} alt="Kategorie-Foto" className="h-full" />
        </div>
        <div className="w-full border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
          <div className="mb-8">
            <div className="text-gray-900 font-bold text-xl mb-2">{title}</div>
            <p
              className="text-gray-700 text-base"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          </div>
        </div>
      </div>
    </>
  );
};
