import React from "react";
import { SEO } from "../components/SEO";
import { Card } from "../components/Card";
import { graphql, Link } from "gatsby";

export const query = graphql`
  query GalleryOverview {
    allNodeGalleryOverview(
      sort: {
        order: ASC
        fields: relationships___field_gallery_overview_taxonomy___weight
      }
    ) {
      edges {
        node {
          relationships {
            field_gallery_overview_taxonomy {
              name
            }
            field_teaser_image {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 220
                    maxHeight: 150
                    fit: COVER
                    background: "#ffffff"
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          body {
            processed
          }
          title
          id
        }
      }
    }
  }
`;

type Props = {
  data: any;
};

const Products = ({ data }: Props): React.ReactElement => {
  return (
    <>
      <SEO
        title="Artikel &amp; Preise"
        metaDescription="Artikel &amp; Preise"
      />

      <div className="flex flex-col m-3 justify-between">
        {data.allNodeGalleryOverview.edges.map((edge: any) => {
          const id = edge.node.id;
          const title = edge.node.title;
          const body = edge.node.body.processed;
          const link =
            edge.node.relationships.field_gallery_overview_taxonomy.name;
          const fluidImage =
            edge.node.relationships.field_teaser_image.localFile.childImageSharp
              .fluid;
          return (
            <Link key={id} to={"/products/" + link}>
              <Card
                fluidImg={fluidImage}
                title={title}
                description={body}
              ></Card>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default Products;
